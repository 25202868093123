import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { ParallaxBanner } from "react-scroll-parallax"
import CountUp from "react-countup"

import Seo from "components/Seo"
import PageHeader from "components/PageHeader"
import Section from "components/Section"

import HeadlineBg from "images/hpc_20220103_1226.jpg"

const TIMELINE = [
  {
    key: "2012",
    label: "2012",
    children: [
      { label: "㈜HPC컨설팅 설립" },
      { label: "노사발전재단 컨설팅 사업 수행" },
    ],
  },
  {
    key: "2013",
    label: "2013",
    children: [
      { label: "일터혁신 컨설팅 사업 수행 (~ 현재)" },
      { label: "고용노동부 일터혁신 교육과정(평가/보상/육성체계) 개발" },
      { label: "AA 양성평등 컨설팅 사업 수주" },
      { label: "삼성화재 사내대학구축 및 직무전문가 육성체계 수립" },
    ],
  },
  {
    key: "2014",
    label: "2014",
    children: [
      { label: "삼성화재 핵심직무 글로벌 아카데미 구축" },
      { label: "한국환경산업기술원 성과평가 및 보수체계 선진화 방안 수립" },
      { label: "대통령 직속 청년위원회 청년멘토링 발전방안 수립" },
      { label: "2014 일터혁신컨설팅 우수기관 포상" },
    ],
  },

  {
    key: "2015",
    label: "2015",
    children: [
      { label: "삼성화재 보험본부 직무교육과정 개발" },
      { label: "한국패션산업연구원 경력개발제도(CDP) 설계" },
      { label: "삼성생명 직무교육체계 수립 및 교육과정개발" },
    ],
  },
  {
    key: "2016",
    label: "2016",
    children: [
      { label: "㈜삼천리이엔지 직무분석 및 성과관리체계 구축" },
      { label: "삼성화재 현장직무 WI(업무혁신) 프로그램 개발" },
      {
        label:
          "삼성화재 성과 개선을 위한 인적경쟁력 강화 전략(저성과자 관리 프로그램) 수립",
      },
    ],
  },
  {
    key: "2017",
    label: "2017",
    children: [
      { label: "한국청소년복지개발원 조직진단 컨설팅" },
      { label: "한국산업인력공단 NCS 직무기술서 제작" },
      { label: "㈜아이센스 직무분석 및 성과·보상체계 설계" },
      { label: "한국고용노동연수원 직업상담원 상담전문과정 개발" },
    ],
  },
  {
    key: "2018",
    label: "2018",
    children: [
      { label: "삼성화재 사무직군 입문 교육과정 개발" },
      { label: "기획재정부 조직문화 활성화 프로그램 위탁 운영" },
      { label: "현대해상 신임대리 승진자 과정 개발" },
      {
        label:
          "㈜뷰웍스 직무기반 인사제도 개선 (직무분석, 평가, 보상, 육성체계)",
      },
    ],
  },
  {
    key: "2019",
    label: "2019",
    children: [
      { label: "한국고용노동연수원 노동교육 효과성 연구 용역" },
      { label: "일터혁신사업 부문 조직 확대" },
      { label: "본사 이전 (아남타워 13층)" },
    ],
  },
  {
    key: "2020",
    label: "2020",
    children: [
      { label: "2020 일터혁신 컨설팅 지원사업 확대" },
      { label: "한국고용노동연수원 조직 혁신방안 연구 용역" },
      { label: "서울의료원 평가·보상체계 개편" },
      { label: "전주시 노사상생형 지역일자리 지원사업 참여" },
      { label: "대통령직속 일자리위원회 일터혁신 TF (2020~ 계속)" },
    ],
  },
  {
    key: "2021",
    label: "2021",
    children: [
      { label: "본사 확장 이전 (S&I 강남빌딩 13층)" },
      { label: "HPC 기업부설연구소 설립" },
      { label: "한국조폐공사 노사파트너십 체계 구축" },
      { label: "고용노동부 장관 표창 (고용노동행정 부문)" },
      { label: "고용노동부 직무중심 인사관리체계 컨설팅 사업 수주" },
    ],
  },
  {
    key: "2022",
    label: "2022",
    children: [
      { label: "본사 확장 이전 (용산센트럴파크타워 12층)" },
      { label: "중소기업진흥공단 구조혁신 컨설팅 사업 수주" },
      { label: "고용노동부 직무중심 인사관리체계 컨설팅 사업 수주" },
      { label: "한국NSK 新인사제도 구축 프로젝트" },
      { label: "재생의료진흥재단 임상연구인력 직무분석" },
    ],
  },
]

export default function AboutPage({ data }) {
  const page = data.wpPage

  console.log("page", page)
  const { historyOfCompany } = page.about

  return (
    <div>
      <Seo title={"HPC컨설팅 회사소개"} />
      <PageHeader
        title={"산업 4.0시대, 최적의 인사 및 생산성 혁신을 지원합니다."}
      />
      <ParallaxBanner
        className="your-class"
        layers={[
          {
            image: HeadlineBg,
            amount: 0.5,
          },
        ]}
        style={{
          height: "480px",
        }}
      >
        <div className="bg-gray-900 opacity-10 absolute inset-0 w-full h-full"></div>
      </ParallaxBanner>
      <div className="py-16 lg:py-24">
        <div className="container mx-auto px-4 lg:px-8">
          <div className="max-w-4xl mb-16 lg:mb-24">
            <Section.Headline eyebrow={"Who We Are"}>
              HPC는 2012년도에 설립되어 국내 일터혁신을 선도하는 Workplace
              innovation 전문기업으로서, 인사혁신(HR innovation)과 생산성
              혁신(Productivity Innovation)을 유기적으로 연계하는 현장중심의
              차별화된 솔루션을 제공합니다.
            </Section.Headline>
          </div>
        </div>

        <div>
          <div className="container mx-auto">
            <div className="lg:w-10/12 xl:w-8/12 mx-auto px-4 lg:px-8">
              <div className="flex flex-row flex-wrap">
                {[
                  {
                    key: "years",
                    title: "업력",
                    value: page.about.keypoints.year,
                    suffix: "년",
                  },
                  {
                    key: "patners",
                    title: "컨설팅 기업",
                    value: page.about.keypoints.clients,
                    suffix: "곳",
                  },
                  {
                    key: "hr",
                    title: "HR전문인력",
                    value: page.about.keypoints.hr,
                    suffix: "명",
                  },
                  {
                    key: "manufactur",
                    title: "제조혁신 전문인력",
                    value: page.about.keypoints.manufacturing,
                    suffix: "명",
                  },
                ].map(item => {
                  return (
                    <div
                      key={item.key}
                      className="w-1/2 sm:w-1/4 mb-8 lg:mb-0 text-right lg:text-left"
                    >
                      <h4 className="text-primary font-medium lg:text-xl mb-1 lg:mb-4">
                        {item.title}
                      </h4>
                      <div className="flex flex-row flex-wrap items-end justify-end lg:justify-start">
                        <h5 className="text-4xl lg:text-5xl font-bold leading-none">
                          <CountUp
                            start={0}
                            end={item.value}
                            duration={2.75}
                            delay={0}
                          />
                        </h5>
                        <p className="text-gray-500 ml-2">{item.suffix}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="py-8 lg:py-16">
          <div className="lg:container mx-auto flex justify-center px-4">
            <div className="inline-flex flex-row flex-wrap">
              <div className="mr-8 lg:mr-16 xl:mr-24 mb-5 lg:mb-0">
                <h3 className="break-words leading-snug xl:leading-snug text-2xl xl:text-3xl font-medium text-gray-900">
                  보다 편리하고, 안전한 세상을 위한 <br />
                  실용주의(實用主義) 인공지능.
                </h3>
              </div>
              <div className="max-w-none lg:max-w-lg">
                <p className="text-gray-700 mb-4 break-words">
                  우리가 제공하는 실용주의 인공지능은 서비스나 제품으로
                  사용화하는데 오랜 시간이 걸리는 미래의 것이 아닙니다. 지금
                  바로 우리의 일상에 인공지능 기술을 더해 업무와 생활에 편의를
                  높일 수 있는 기술 즉, 'AI PLUS'입니다.
                </p>
                <p className="text-gray-700 mb-4 break-words">
                  더 편리한 우리의 일상, 더 안전한 세상을 만들기 위해 노력하는
                  것, 이것이 바로 이스트소프트가 만들어 가는 실용주의
                  인공지능입니다.
                </p>
                <p className="text-gray-700 mb-4 break-words">
                  이스트소프트는 고도화된 인공지능으로 더욱 안전하고 편리한
                  세상을 만들어 나가겠습니다.
                </p>
              </div>
            </div>
          </div>
        </div> */}

      <div className="bg-gray-50 py-16">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="mb-8">
            <h3 className="break-words leading-snug xl:leading-snug text-2xl xl:text-3xl font-medium text-gray-900">
              변하지 않는 HPC컨설팅의 핵심가치
            </h3>
          </div>
          <div className="flex flex-row flex-wrap -mx-4 xl:-mx-8">
            <div className="w-full lg:w-1/3 px-4 xl:px-8">
              <div className="mb-8 lg:mb-0">
                <h3 className="text-gray-600 text-xl lg:text-xl leading-snug break-words">
                  HR혁신과 생산성 혁신을{" "}
                  <span className="text-gray-900 font-medium">통합적 접근</span>
                  을 통한 근본적인 혁신을 추구합니다.
                </h3>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4 xl:px-8">
              <div className="mb-8 lg:mb-0">
                <h3 className="text-gray-600 text-xl lg:text-xl leading-snug break-words">
                  실질적으로 기업현장에 정착·운영될 수 있도록{" "}
                  <span className="text-gray-900 font-medium">
                    실행기반의 컨설팅
                  </span>
                  을 제공합니다.
                </h3>
              </div>
            </div>
            <div className="w-full lg:w-1/3 px-4 xl:px-8">
              <div className="mb-0 lg:mb-0">
                <h3 className="text-gray-600 text-xl lg:text-xl leading-snug break-words">
                  HRM, HRD, 제조혁신 등 각 분야의 풍부한 경험을 지닌{" "}
                  <span className="text-gray-900 font-medium">
                    최고 수준 전문가
                  </span>
                  가 함께합니다.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Section>
          <div className="container mx-auto px-4 lg:px-8">
            <div className="mb-8 lg:mb-16 text-center max-w-2xl mx-auto">
              <Section.Headline eyebrow={"History"}>
                국내 HR 컨설팅 및 생산성 혁신 솔루션을 제공하며 파트너와 함께
                성장해 왔습니다.
              </Section.Headline>
            </div>
          </div>
          <div className="px-4 lg:container mx-auto">
            <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8 justify-center -mt-5">
              {page?.about.clients.map((item, index) => {
                return (
                  <div
                    key={`client-${index}`}
                    className="w-1/3 lg:w-1/5 px-4 lg:px-8 pb-5"
                  >
                    <div className="relative">
                      <div
                        className=""
                        style={{ paddingBottom: "56.25%" }}
                      ></div>
                      <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                        <GatsbyImage
                          style={
                            {
                              // position: "absolute",
                              // height: "100%",
                              // width: "100%",
                              // inset: 0,
                              // objectFit: "cover",
                            }
                          }
                          image={
                            item.logo.localFile?.childImageSharp
                              ?.gatsbyImageData
                          }
                          alt={item.title}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Section>
      </div>

      <div className="bg-white">
        <Section>
          <div className="container mx-auto px-4 lg:px-8">
            <div className="mb-8 lg:mb-16 text-center max-w-2xl mx-auto">
              <Section.Headline eyebrow={"History"}>
                HPC컨설팅 연혁
              </Section.Headline>
            </div>
          </div>
          <div className="max-w-2xl mx-auto px-4 lg:px-8">
            <div className="relative">
              <div
                className="bg-gray-500 absolute top-0 bottom-0 ml-32"
                style={{ width: "2px" }}
              ></div>
              {historyOfCompany.map(group => {
                return (
                  <div key={group.label}>
                    <div className="relative">
                      <span className="text-2xl font-bold leading-none">
                        {group.label}
                      </span>
                      <div
                        className="top-0 absolute ml-32"
                        style={{ left: "-9px" }}
                      >
                        <span className="w-5 h-5  bg-primary rounded-full inline-flex items-center justify-center">
                          <span className="w-3 h-3 bg-white rounded-full inline-block"></span>
                        </span>
                      </div>
                    </div>
                    <div className="pb-4">
                      {group.timeline.map((item, index) => {
                        return (
                          <div
                            key={`${group.key}-${index}`}
                            className="relative ml-32 pl-12 my-5"
                          >
                            <div className="absolute" style={{ left: "-5px" }}>
                              <span className="w-3 h-3 bg-gray-500 rounded-full inline-block"></span>
                            </div>
                            <div className="text-gray-800">{item.title}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </Section>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query AboutPage {
    wpPage(slug: { eq: "about" }) {
      id
      title
      about {
        historyOfCompany {
          label
          timeline {
            title
          }
        }
        keypoints {
          year
          clients
          hr
          manufacturing
        }
        clients {
          title
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            id
          }
        }
      }
    }
  }
`
